@font-face {
    font-family: "fordngbs-antenna-re";
    src: url("https://www.ford.com/cmslibs/etc/designs/common/skin/ford/fonts/AntennaRegular/regular.woff");
}



body {
    font-family: "fordngbs-antenna-re";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #4d4d4d;
}

a {
    @apply text-primary;
    @apply underline text-blue-600;
}

a:active,
a:hover {
    @apply text-secondary;
}

.notification-container {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}
